import React from "react";
import { ImLocation2 } from "react-icons/im";
import { BsFacebook } from "react-icons/bs";
import { MdContactPhone } from "react-icons/md";

const AdmissionContactCard = () => {
  return (
    <div className="container mx-auto">
      <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-3 mt-10 px-8">
        {/* london card  */}

        <div className="card bg-base-100 shadow-md">
          <div className="card-body">
            <img
              className="w-10"
              src="https://upload.wikimedia.org/wikipedia/en/thumb/a/ae/Flag_of_the_United_Kingdom.svg/1200px-Flag_of_the_United_Kingdom.svg.png"
              alt="LSOE MAIN OFFICE"
            />
            <h2 className="card-title">LSOE LONDON OFFICE</h2>
            <p className="text-sm font-normal">
              <ImLocation2 className="inline" />
              <strong> Address:</strong> 5 Station Parade, Hornchurch, Elm Park,
              London, RM12 5AB
            </p>
            <p className="text-sm font-normal">
              <BsFacebook className="inline" />{" "}
              <a
                href="https://www.facebook.com/Londonschoolofexcellence/"
                className="link link-dark"
                alt="Facebook"
                target="_blank"
                rel="noreferrer"
              >
                Facebook
              </a>
            </p>
            <p className="text-sm font-normal">
              <MdContactPhone className="inline" />{" "}
              <strong>Contact Person:</strong> N Haque
            </p>
          </div>
        </div>
        {/* Leeds card  */}

        <div className="card bg-base-100 shadow-md">
          <div className="card-body">
            <img
              className="w-10"
              src="https://upload.wikimedia.org/wikipedia/en/thumb/a/ae/Flag_of_the_United_Kingdom.svg/1200px-Flag_of_the_United_Kingdom.svg.png"
              alt="LSOE MAIN OFFICE"
            />
            <h2 className="card-title">LSOE LEEDS OFFICE</h2>
            <p className="text-sm font-normal">
              <ImLocation2 className="inline" />
              <strong> Address:</strong> 5th - 18th Floors, 67 Albion Street
              Pinnacle, Leeds, LS1 5AA
            </p>
            <p className="text-sm font-normal">
              <BsFacebook className="inline" />{" "}
              <a
                href="https://www.facebook.com/Londonschoolofexcellence/"
                className="link link-dark"
                alt="Facebook"
                target="_blank"
                rel="noreferrer"
              >
                Facebook
              </a>
            </p>
            <p className="text-sm font-normal">
              <MdContactPhone className="inline" />{" "}
              <strong>Contact Person:</strong> Ms. David Joshep
            </p>
          </div>
        </div>

        {/* Birmingham card  */}

        <div className="card bg-base-100 shadow-md">
          <div className="card-body">
            <img
              className="w-10"
              src="https://upload.wikimedia.org/wikipedia/en/thumb/a/ae/Flag_of_the_United_Kingdom.svg/1200px-Flag_of_the_United_Kingdom.svg.png"
              alt="LSOE MAIN OFFICE"
            />
            <h2 className="card-title">LSOE Birmingham OFFICE</h2>
            <p className="text-sm font-normal">
              <ImLocation2 className="inline" />
              <strong> Address:</strong> 588 Warwick Road Tyseley, Birmingham,
              B11 2HR
            </p>
            <p className="text-sm font-normal">
              <BsFacebook className="inline" />{" "}
              <a
                href="https://www.facebook.com/Londonschoolofexcellence/"
                className="link link-dark"
                alt="Facebook"
                target="_blank"
                rel="noreferrer"
              >
                Facebook
              </a>
            </p>
            <p className="text-sm font-normal">
              <MdContactPhone className="inline" />{" "}
              <strong>Contact Person:</strong> Mr. A Tanim
            </p>
          </div>
        </div>

        {/* BANGLADESH Card */}
        <div className="card bg-base-100 shadow-md">
          <div className="card-body">
            <img
              className="w-10"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f9/Flag_of_Bangladesh.svg/1200px-Flag_of_Bangladesh.svg.png"
              alt="LSOE MAIN OFFICE"
            />
            <h2 className="card-title">LSOE BANGLADESH</h2>
            <p className="text-sm font-normal">
              <ImLocation2 className="inline" />
              <strong> Address:</strong> 70, Point View Shopping Centre (1st
              Floor) Amborkhana, Sylhet.
            </p>
            <p className="text-sm font-normal">
              <BsFacebook className="inline" />{" "}
              <a
                href="https://www.facebook.com/LSOE.BD"
                className="link link-dark"
                alt="Facebook"
                target="_blank"
                rel="noreferrer"
              >
                Facebook
              </a>
            </p>
            <p className="text-sm font-normal">
              <MdContactPhone className="inline" />{" "}
              <strong>Contact Person:</strong> Mr. Tahmid Hasan
            </p>
          </div>
        </div>

        {/* NIGERIA Card */}
        <div className="card bg-base-100 shadow-md">
          <div className="card-body">
            <img
              className="w-10"
              src="https://cdn.britannica.com/68/5068-004-72A3F250/Flag-Nigeria.jpg"
              alt="LSOE MAIN OFFICE"
            />
            <h2 className="card-title">LSOE NIGERIA</h2>
            <p className="text-sm font-normal">
              <ImLocation2 className="inline" />
              <strong> Address:</strong> Bankole street, Magodo, Lagos, Nigeria
            </p>
            <p className="text-sm font-normal">
              <BsFacebook className="inline" />{" "}
              <a
                href="https://www.facebook.com/LondonschoolofexcellenceNG/"
                className="link link-dark"
                alt="Facebook"
                target="_blank"
                rel="noreferrer"
              >
                Facebook
              </a>
            </p>
            <p className="text-sm font-normal">
              <MdContactPhone className="inline" />{" "}
              <strong>Contact Person:</strong> KASHIMAWO Olalekan
            </p>
          </div>
        </div>

        {/* SRI LANKA Card */}
        <div className="card bg-base-100 shadow-md">
          <div className="card-body">
            <img
              className="w-10"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/11/Flag_of_Sri_Lanka.svg/1920px-Flag_of_Sri_Lanka.svg.png"
              alt="LSOE MAIN OFFICE"
            />
            <h2 className="card-title">LSOE Sri Lanka</h2>
            <p className="text-sm font-normal">
              <ImLocation2 className="inline" />
              <strong> Address:</strong> 214/1, Opposite the Temple ,
              Puwakpitiya, Galewela , Matale District, Sri lanka
            </p>
            <p className="text-sm font-normal">
              <BsFacebook className="inline" />{" "}
              <a
                href="https://facebook.com/Londonschoolofexcellence/"
                className="link link-dark"
                alt="Facebook"
                target="_blank"
                rel="noreferrer"
              >
                Facebook
              </a>
            </p>
            <p className="text-sm font-normal">
              <MdContactPhone className="inline" />{" "}
              <strong>Contact Person:</strong> M Rifthy
            </p>
          </div>
        </div>

        {/* TUNISIA Card */}

        <div className="card bg-base-100 shadow-md">
          <div className="card-body">
            <img
              className="w-10"
              src="https://cdn.britannica.com/41/3041-004-F1D6DEFC/Flag-Tunisia.jpg"
              alt="LSOE MAIN OFFICE"
            />
            <h2 className="card-title">LSOE TUNISIA</h2>
            <p className="text-sm font-normal">
              <ImLocation2 className="inline" />
              <strong> Address:</strong> 28 rue Mohaled Mhiri, Naser 1, 2037
              Ariana
            </p>
            <p className="text-sm font-normal">
              <BsFacebook className="inline" />{" "}
              <a
                href="https://www.facebook.com/lsoetn"
                className="link link-dark"
                alt="Facebook"
                target="_blank"
                rel="noreferrer"
              >
                Facebook
              </a>
            </p>
            <p className="text-sm font-normal">
              <MdContactPhone className="inline" />{" "}
              <strong>Contact Person:</strong> Mr. Ghassen Jabali
            </p>
          </div>
        </div>

        {/* GHANA Card */}
        <div className="card bg-base-100 shadow-md">
          <div className="card-body">
            <img
              className="w-10"
              src="https://cdn.britannica.com/54/5054-004-A09ABCDF/Flag-Ghana.jpg"
              alt="LSOE MAIN OFFICE"
            />
            <h2 className="card-title">LSOE GHANA</h2>
            <p className="text-sm font-normal">
              <ImLocation2 className="inline" />
              <strong> Address:</strong> GA – 204 – 3830, Achimota – Abofu,
              Accra
            </p>
            <p className="text-sm font-normal">
              <BsFacebook className="inline" />{" "}
              <a
                href="https://www.facebook.com/profile.php?id=100063681425768"
                className="link link-dark"
                alt="Facebook"
                target="_blank"
                rel="noreferrer"
              >
                Facebook
              </a>
            </p>
            <p className="text-sm font-normal">
              <MdContactPhone className="inline" />{" "}
              <strong>Contact Person:</strong> Mr. Washington Cobi
            </p>
          </div>
        </div>

        {/* GUINEA Card */}
        <div className="card bg-base-100 shadow-md">
          <div className="card-body">
            <img
              className="w-10"
              src="https://cdn.britannica.com/56/5056-004-0E251CE7/Flag-Guinea.jpg"
              alt="LSOE MAIN OFFICE"
            />
            <h2 className="card-title">LSOE GUINEA</h2>
            <p className="text-sm font-normal">
              <ImLocation2 className="inline" />
              <strong> Address:</strong> Immeuble Barry et fils, route le
              prince, Bambeto, Conakry
            </p>
            <p className="text-sm font-normal">
              <BsFacebook className="inline" />{" "}
              <a
                href="https://www.facebook.com/Londonschoolofexcellence/"
                className="link link-dark"
                alt="Facebook"
                target="_blank"
                rel="noreferrer"
              >
                Facebook
              </a>
            </p>
            <p className="text-sm font-normal">
              <MdContactPhone className="inline" />{" "}
              <strong>Contact Person:</strong> Miss. Jalloh Saliu Jan
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdmissionContactCard;
