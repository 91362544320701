import React from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";

const AdmissionFooter = () => {
  return (
    <>
      <footer className="footer lg:grid-cols-3 lg:justify-items-center p-10 bg-base-100 text-base-content">
        <div className="grid-cols-1">
          <Link to="/">
            <ReactSVG
              className="w-60 mb-3"
              src="https://res.cloudinary.com/lsoe/image/upload/v1698255900/images/LSOE_Logo_lon5ab.svg"
            />
          </Link>
          {/* <p className="text-sm font-normal text-gray-600">
        LSOE, an independent career hub located in Hornchurch, Romford, is a
        combination of two organizations, London School of Excellence and
        London Source of Employment. Established in 2013, LSOE aims to
        create skilled human resources and has grown to become a prominent
        career guide recruitment agency in the UK.
      </p> */}
        </div>
        <div>
          <span className="footer-title">Certified by:</span>
          <img
            src="https://res.cloudinary.com/lsoe/image/upload/v1678978977/BritishCouncil_Logo_phr8nk.png"
            alt="Logo"
            className="w-60 mb-3"
          />
        </div>
        <div>
          <span className="footer-title">Quick Links</span>

          <a
            className="link link-hover"
            href="https://join.com/companies/londonschoolofexcellence"
            target="_blank"
            rel="noreferrer"
          >
            LSOE Career
          </a>

          <Link to="/about-admission-hub" className="link link-hover">
            About Us
          </Link>
          <Link
            to="/contact-london-school-of-excellence"
            className="link link-hover"
          >
            Contact Us
          </Link>
          <Link to="/privacy-policy" className="link link-hover">
            Privacy policy
          </Link>
          <Link to="/modern-slavery-policy" className="link link-hover">
            Modern Slavery Policy
          </Link>
        </div>
        <div>
          <span className="footer-title">London School of Excellence</span>
          <h6>
            <i className="fas fa-map-marker-alt footer-icon mx-2"></i>5 Station
            Parade, Hornchurch, Elm Park, London, RM12 5AB, UK
          </h6>
          <h6>
            <i className="fas fa-map-marker-alt footer-icon mx-2"></i>
            261 Harehills Lane, Leeds, LS8 3RG, UK
          </h6>
          <h6>
            <i className="fas fa-map-marker-alt footer-icon mx-2"></i>
            588 Warwick Road Tyseley, Birmingham, B11 2HR, UK
          </h6>
          <h6>
            <i className="fas fa-phone-volume footer-icon mx-2"></i>
            <a href="tel:+447901024151">+44 (0)1708784763</a>
          </h6>
          <h6>
            <i className="fas fa-fax footer-icon mx-2"></i>
            <a href="tel:+44(0)1708397393">+44 (0)7574091716</a>
          </h6>
          <h6>
            <i className="fas fa-envelope footer-icon mx-2"></i>
            <a href="mailto:info@lsoe.org.uk">
              info.office@londonschoolofexcellence.com
            </a>
          </h6>
          <span className="footer-title">Company Registration 08487750</span>
        </div>
      </footer>
      <footer className="footer px-10 py-4 border-t bg-base-100 text-base-content border-base-300">
        <div className="items-center grid-flow-col">
          <div>
            <p>Copyright © 2023 - All right reserved by LSOE Ltd</p>
          </div>
        </div>
        <div className="md:place-self-center md:justify-self-end">
          <div className="grid grid-flow-col gap-4">
            <a
              href="https://www.facebook.com/Londonschoolofexcellence/"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2em"
                height="2em"
                viewBox="0 0 24 24"
                className="fill-current"
              >
                <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"></path>
              </svg>
            </a>
            <a
              href="https://www.linkedin.com/company/london-school-of-excellence/"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2em"
                height="2em"
                viewBox="0 0 24 24"
                className="fill-current"
              >
                <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"></path>
              </svg>
            </a>
            <a
              href="https://twitter.com/LsoeLtd"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2em"
                height="2em"
                viewBox="0 0 24 24"
                className="fill-current"
              >
                <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"></path>
              </svg>
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=8801766448121"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2em"
                height="2em"
                viewBox="0 0 24 24"
                className="fill-current"
              >
                <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z"></path>
              </svg>
            </a>
            <a
              href="https://www.youtube.com/@lsoeteam"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2em"
                height="2em"
                viewBox="0 0 24 24"
                className="fill-current"
              >
                <path d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z"></path>
              </svg>
            </a>
            <a
              href="https://www.tiktok.com/@lsoe_team"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2em"
                height="2em"
                viewBox="0 0 24 24"
                className="fill-current"
              >
                <path d="M12.525.02c1.31-.02 2.61-.01 3.91-.02.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z"></path>
              </svg>
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default AdmissionFooter;
