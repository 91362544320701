import React from "react";

const SlaveryPolicy = () => {
  return (
    <>
      <div className="flex items-center justify-center py-5 bg-gray-200">
        <div className="container">
          <div className="bg-white rounded-lg shadow-lg p-5">
            <h1 class="text-3xl font-bold text-gray-800 mb-4">
              Modern Slavery Policy
            </h1>

            <h2 class="text-2xl font-semibold text-gray-700 mt-6">
              1. Introduction
            </h2>
            <p class="mt-2">
              At <strong>London School of Excellence Ltd.</strong>, we are
              committed to preventing{" "}
              <strong>modern slavery and human trafficking</strong> in all
              aspects of our operations and supply chains. We recognize our
              responsibility to implement effective measures ensuring that
              modern slavery has no place within our business activities.
            </p>

            <h2 class="text-2xl font-semibold text-gray-700 mt-6">2. Scope</h2>
            <p class="mt-2">This policy applies to:</p>
            <ul class="list-disc list-inside mt-2 space-y-1">
              <li>
                All <strong>employees</strong> of London School of Excellence
                Ltd.
              </li>
              <li>
                <strong>Contractors, suppliers, and third parties</strong>{" "}
                providing goods or services to the company.
              </li>
            </ul>

            <h2 class="text-2xl font-semibold text-gray-700 mt-6">
              3. Policy Statement
            </h2>
            <p class="mt-2">
              We are dedicated to ensuring that modern slavery and human
              trafficking do not occur within our business. To achieve this, we
              will:
            </p>
            <ul class="list-disc list-inside mt-2 space-y-1">
              <li>
                <strong>Comply</strong> with all applicable laws and regulations
                related to modern slavery.
              </li>
              <li>
                <strong>Raise awareness</strong> among employees, contractors,
                and suppliers about the risks of modern slavery.
              </li>
              <li>
                <strong>Conduct due diligence</strong> on suppliers and
                contractors to assess and mitigate potential risks.
              </li>
              <li>
                <strong>Take immediate action</strong> to address any identified
                instances of modern slavery.
              </li>
              <li>
                <strong>Provide training</strong> to employees and partners on
                recognizing and reporting modern slavery.
              </li>
              <li>
                <strong>Regularly review and update</strong> this policy to
                ensure its continued effectiveness.
              </li>
            </ul>

            <h2 class="text-2xl font-semibold text-gray-700 mt-6">
              4. Reporting Modern Slavery Concerns
            </h2>
            <p class="mt-2">
              We encourage anyone who suspects modern slavery or human
              trafficking within our operations or supply chains to report their
              concerns through our <strong>whistleblowing channel</strong>. All
              reports will be taken seriously, investigated, and appropriate
              action will be taken to address any concerns raised.
            </p>

            <h2 class="text-2xl font-semibold text-gray-700 mt-6">
              5. Commitment to Continuous Improvement
            </h2>
            <p class="mt-2">
              London School of Excellence Ltd. remains dedicated to
              strengthening its policies and procedures to combat modern
              slavery. We will regularly review and assess our practices to
              ensure compliance and effectiveness in tackling this issue.
            </p>

            <p class="mt-6 font-semibold">
              For any concerns or further information, please contact us at{" "}
              <a
                href="mailto:info@londonschoolofexcellence.com"
                class="text-blue-600 hover:underline"
              >
                info@londonschoolofexcellence.com
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SlaveryPolicy;
